import React from 'react'

import Layout from '@components/layout'
import {
  getStaticPage,
  modules,
  allProducts,
  pageSeo,
  SiteSettings,
} from '@lib/api'

import { Module } from '@modules/index'
import { extractPageSchema } from '@lib/schema'

const getOrganisationSchema = (site: SiteSettings) => {
  return {
    '@type': 'Organization',
    name: site.seo.siteTitle,
    logo: `${process.env.CANONICAL_URL}/android-chrome-256x256.png`,
    sameAs: site.footer.blocks[3]?.social.map(
      (socialAccount) => socialAccount.url
    ),
    url: process.env.CANONICAL_URL,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+81 03 6206 0468',
        contactType: 'customer service',
        areaServed: 'Japan',
      },
    ],
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'Japan',
      postalCode: '151-0063',
      addressRegion: 'Shibuya',
      addressLocality: 'Tokyo',
    },
  }
}

const getSchema = (page, site) => {
  const modulesSchema = extractPageSchema(page, site)
  const organisationSchema = getOrganisationSchema(site)
  return {
    '@context': 'http://schema.org',
    '@graph': [organisationSchema, ...modulesSchema],
  }
}

const Home = ({ data }) => {
  const { site, page } = data

  return (
    <Layout site={site} page={page} schema={getSchema(page, site)}>
      {page.modules?.map((module, key) => (
        <Module
          key={key}
          uniqueId={key}
          module={module}
          collectionProducts={page.products}
          featuredProducts={page.featuredProducts}
        />
      ))}
    </Layout>
  )
}

export async function getStaticProps({ preview, previewData, locale }) {
  const pageData = await getStaticPage({
    pageData: `*[_type == "homePage"] | order(_updatedAt desc)[0]{
      hasTransparentHeader,
      modules[]{
        ${modules(locale)}
      },
      "products": ${allProducts({ preview, locale })},
      "featuredProducts": featuredProducts[]->productID,
      "seo": {
        ${pageSeo(locale)}
      }
    }
  `,
    preview: {
      active: preview,
      token: previewData?.token,
    },
    locale,
  })

  return {
    props: {
      data: pageData,
    },
    revalidate: 60,
  }
}

export default Home
